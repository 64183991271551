import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';


const HEIMDALL_SESSION_GQL = gql(`
  mutation heimdallAuthorizationFlowJWT(
    $input: HeimdallAuthorizationFlowInput!
  ) {
    heimdallAuthorizationFlow(input: $input) {
      session {
        uid
      }
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

export type HeimdallSessionResult = {
  response?: unknown
}

export const useHeimdallSession = (
  cardNo: string,
  clinicDomain: string,
  dob: string,
  option: Option<HeimdallSessionResult, Error> = {}): UseQueryResult<HeimdallSessionResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getSession = async (): Promise<HeimdallSessionResult> => {
    const input = {
      "providerApplicationUid": Variables.providerApplicationUid,
      "providerInput": {
        "maxicare_card_no": cardNo,
        "maxicare_clinic_domain": clinicDomain,
        "maxicare_dob": dob
      },
      "applicationInput": {
        "patient_verification_fields": [
          {
            "key": "identifier",
            "value": cardNo
          }
        ]
      }
    }

    try {
      const result = await request(
        Variables.URL,
        HEIMDALL_SESSION_GQL,
        { input }
      ).then((data) => data)
        .catch((error) => error)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useQuery<HeimdallSessionResult, Error>({
    queryKey: [QueryKey.HeimdallSession],
    queryFn: () => getSession(),
    ...options,
  })


}