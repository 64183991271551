//import liraries
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { Lotties } from '../../assets';
import Lottie from "lottie-react";
import { Header } from '../../components';
import { Colors, Variables } from '../../constants';
import { useParams } from 'react-router-dom';

export const Success = () => {

  const [timer, setTimer] = useState(60)
  const currentTime = React.useRef(new Date().toLocaleString());

  const { patientPhone, maxicareId, isDeviceCreated } = useParams()

  const clinicDomain = Variables.clinicDomain

  console.log({ patientPhone, maxicareId, clinicDomain })


  const QrCodeUrl = useMemo(() => {
    if (patientPhone && isDeviceCreated === 'false' && maxicareId) {
      console.log('QR code enter')
      return `${Variables.DOMAIN_URL}/cleo/maxicare/qr_login/CDl9jCOKn7gbgwuqJj1Y3Ai5ygB8ZI8O?unencoded=cleo:${clinicDomain}%7C${patientPhone}%7C${maxicareId}`
    }
    return null
  }, [patientPhone, isDeviceCreated, maxicareId, clinicDomain])

  console.log({ QrCodeUrl })

  React.useEffect(() => {
    // Clear session storage
    sessionStorage.removeItem('session')
    sessionStorage.removeItem('doctorsNoteUploadId')
    const interval = setInterval(() => {
      if (timer === 0) {
        window.location.href = '/'
      }
      setTimer(timer => {
        if (timer > 0) {
          return timer - 1
        }
        return timer
      })
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  return (

    <Flex bg="#F4F6F8" flexDirection={'column'} flex={1}  >
      <Header clinicDomain={clinicDomain} />
      <Flex
        flex={1}
        pt={4}
        ml={[0, "40px"]}
        mr={[0, "40px"]}
        flexDirection={'column'}
        alignItems={'center'}
        borderRadius={8}
        bg={'white'}
      >

        <Flex
          alignItems={'center'}
          height={'100%'}
          width={'100%'}
          flexDirection={'column'}
          justifyItems={'center'}>
          <Text align={'center'} fontWeight={'600'} fontSize={24} p={4} pb="8px">Appointment Booking successful!</Text>
          <Text align={'center'} fontWeight={'400'} fontSize={16} color={Colors.darkestShade}>{currentTime.current}</Text>

          {!QrCodeUrl && (<Box
            width={'500px'}
            height={'500px'}
            sx={{
              '@media screen and (max-width: 768px)': {
                marginBottom: '-100px',
                marginTop: '-100px',
              }
            }} >
            <Lottie
              animationData={Lotties.partyBot}
              loop={false}
            />
          </Box>)}

          {QrCodeUrl && (
            <Flex alignItems={'center'} flexDirection={'column'}>
              <Box alignItems={'center'} width={200} mt={4} mb={4} border="6px solid black" borderRadius={4}>
                <Image height={200} src={QrCodeUrl} alt='qrcode' />
              </Box>
              <Text color={Colors.black} textAlign={'center'} mb={10} mt={10} fontSize={24} fontWeight={"600"}>
                Scan to add Maxicare on Facebook Messenger to receive your appointment reminders
              </Text>
            </Flex>
          )}


          <Box textAlign={'center'} w="100%" >
            <Text textAlign={'center'} sx={{
              '@media screen and (max-width: 768px)': {
                fontSize: '14px'
              }
            }} fontWeight={'600'} fontSize={24}>
              You will receive an appointment confirmation
              message from the Maxicare
            </Text>
          </Box>

          <Button
            _hover={{
              bg: Colors.primaryButton
            }}
            onClick={() => {
              window.location.href = '/'
            }}
            bg={Colors.primaryButton}
            textColor={'white'}
            width={'90%'}
            mt={4}
            mb={4}
          >
            <Text fontWeight={'600'} fontSize={14}>Close Window ({timer})</Text>
          </Button>

        </Flex>
      </Flex>

    </Flex>


  );
};
