import { Icon } from "@chakra-ui/react"

export const Doctor = ({ height = 35, width = 35, fill = "none" }) => {
  return (
    <Icon viewBox="0 0 35 35" height={height} width={width}>
      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.75 11.25C5.875 11.25 4.375 9.75 4.375 7.875C4.375 6.02344 5.875 4.5 7.75 4.5C9.60156 4.5 11.125 6.02344 11.125 7.875C11.125 9.75 9.60156 11.25 7.75 11.25ZM7.75 5.625C6.50781 5.625 5.5 6.65625 5.5 7.875C5.5 9.11719 6.50781 10.125 7.75 10.125C8.96875 10.125 10 9.11719 10 7.875C10 6.65625 8.96875 5.625 7.75 5.625ZM9.83594 11.625C11.5703 11.625 13 13.0547 12.9766 14.7891V15.9375C12.9766 16.2656 12.7422 16.5 12.4141 16.5C12.1094 16.5 11.8516 16.2656 11.8516 15.9375V14.7891C11.8516 13.7109 11.0312 12.8438 9.97656 12.7734V13.7578C10.6328 13.9219 11.1016 14.5078 11.1016 15.1875V15.9375C11.1016 16.0547 11.0781 16.1484 11.0078 16.2188L10.75 16.4531C10.6797 16.5234 10.5625 16.5234 10.4922 16.4531L10.2344 16.1953C10.1641 16.125 10.1641 16.0078 10.2344 15.9141L10.3516 15.7969V15.2344C10.3516 14.8594 10.1172 14.5078 9.74219 14.4609C9.27344 14.3906 8.85156 14.7422 8.85156 15.1875V15.7969L8.99219 15.9141C9.0625 16.0078 9.0625 16.125 8.99219 16.1953L8.73438 16.4531C8.66406 16.5234 8.54688 16.5234 8.47656 16.4531L8.21875 16.2188C8.14844 16.1484 8.125 16.0547 8.125 15.9375V15.2578C8.125 14.5547 8.57031 13.9219 9.25 13.7578V12.8906C8.75781 13.0547 8.24219 13.125 7.75 13.125C7.23438 13.125 6.71875 13.0547 6.25 12.8906V13.9453C6.78906 14.1094 7.1875 14.6016 7.1875 15.1875C7.1875 15.9141 6.57812 16.5 5.875 16.5C5.14844 16.5 4.5625 15.9141 4.5625 15.1875C4.5625 14.6016 4.9375 14.1094 5.5 13.9453V12.7734C4.44531 12.8438 3.625 13.7109 3.625 14.7891V15.9375C3.625 16.2656 3.36719 16.5 3.0625 16.5C2.73438 16.5 2.5 16.2656 2.5 15.9375V14.7891C2.5 13.0547 3.90625 11.625 5.64062 11.625C5.89844 11.625 6.15625 11.6953 6.39062 11.7891C6.8125 11.9297 7.25781 12 7.75 12C8.21875 12 8.66406 11.9297 9.08594 11.7891C9.32031 11.6953 9.57812 11.625 9.83594 11.625ZM6.4375 15.1875C6.4375 14.8828 6.17969 14.625 5.875 14.625C5.54688 14.625 5.3125 14.8828 5.3125 15.1875C5.3125 15.5156 5.54688 15.75 5.875 15.75C6.17969 15.75 6.4375 15.5156 6.4375 15.1875Z" fill="#697481" />
      </svg>
    </Icon>
  )
}