//import liraries
import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Header, Loading } from '../../components';

//Check that user is able to book on this page by calling the backend
// If yes, then redirect to the booking page
// If no, then show the error message

export const ValidateUser = () => {

  const [validating, setValidating] = useState(true)

  // Get the patient ID and phone number from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const maxicareId = urlParams.get('maxicare_id');
  const phoneNumber = urlParams.get('phone');
  const email = urlParams.get('email');
  const firstName = urlParams.get('first_name');
  const lastName = urlParams.get('last_name');
  const clinic = urlParams.get('clinic'); // TO show the slots for the clinic

  console.log('patientId', maxicareId)
  console.log('phoneNumber', phoneNumber)
  console.log('clinic', clinic)

  /**
   * TODO: We need to validate the patient can book an appointment before redirecting to the typeform
   * By calling the backend with the maxicare
   **/

  const typeformUrl = `https://bot-md.typeform.com/to/j6eZDP9S#maxicare_id=${maxicareId}&phone=${phoneNumber}&clinic=${clinic}&email=${email}&first_name=${firstName}&last_name=${lastName}`;

  // Redirect to typeform and pass it the params

  // const redirect = useCallback(() => {
  //   if (maxicareId && phoneNumber && clinic) {
  //     window.location.replace(typeformUrl);
  //   }
  // }, [maxicareId, phoneNumber, clinic, typeformUrl])

  useEffect(() => {
    setTimeout(() => {
      setValidating(false)
      // redirect()
    }, 2000);
  }, [])

  return (
    <Flex bg="#F4F6F8" h="98vh" flexDirection={'column'}>
      <Header />
      {validating && <Loading text='Validating user...' />}

      {!validating && (<Box h="98vh">
        <iframe style={{ width: '90%', height: '98%', borderRadius: 10, display: 'flex', alignSelf: 'center', margin: '0 auto' }} title='main'
          src={typeformUrl} />
      </Box>)}

    </Flex>
  );
};


