import { Icon } from "@chakra-ui/react"

export const LocationPin = ({ height = 35, width = 35, fill = "none" }) => {
  return (
    <Icon viewBox="0 0 35 35" height={height} width={width}>
      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.875 9C9.875 10.0547 9.03125 10.875 8 10.875C6.94531 10.875 6.125 10.0547 6.125 9C6.125 7.96875 6.94531 7.125 8 7.125C9.03125 7.125 9.875 7.96875 9.875 9ZM8 8.25C7.57812 8.25 7.25 8.60156 7.25 9C7.25 9.42188 7.57812 9.75 8 9.75C8.39844 9.75 8.75 9.42188 8.75 9C8.75 8.60156 8.39844 8.25 8 8.25ZM12.5 9C12.5 11.0625 9.75781 14.6953 8.53906 16.2188C8.25781 16.5703 7.71875 16.5703 7.4375 16.2188C6.21875 14.6953 3.5 11.0625 3.5 9C3.5 6.51562 5.49219 4.5 8 4.5C10.4844 4.5 12.5 6.51562 12.5 9ZM8 5.625C6.125 5.625 4.625 7.14844 4.625 9C4.625 9.30469 4.71875 9.75 4.97656 10.3594C5.21094 10.9219 5.5625 11.5781 5.96094 12.2344C6.64062 13.3125 7.41406 14.3438 8 15.0938C8.5625 14.3438 9.33594 13.3125 10.0156 12.2344C10.4141 11.5781 10.7656 10.9219 11 10.3594C11.2578 9.75 11.375 9.30469 11.375 9C11.375 7.14844 9.85156 5.625 8 5.625Z" fill="#697481" />
      </svg>
    </Icon>
  )
}