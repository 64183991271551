import {
  createBrowserRouter,
} from "react-router-dom";

import { Calendar, AppointmentSelect, OnlineBooking, Success, ValidateUser, CommunicationMethodSelection, Main, DoctorsNote } from "./page";

export const router = createBrowserRouter([
  {
    path: "/:maxicareId?/:dob?/:clinicDomain?",
    Component: Main,
  },
  {
    path: "/onlineBooking/:maxicareId",
    Component: OnlineBooking,
  },
  {
    path: "/calendar/:id?/:maxicareId?",
    Component: Calendar,
  },
  {
    path: "/validate/:maxicareId",
    Component: ValidateUser,
  },
  {
    path: "/success/:patientPhone?/:maxicareId?/:isDeviceCreated?",
    Component: Success
  },
  {
    path: "/appointmentSelection/:id?/:doctorsOrder?",
    Component: AppointmentSelect
  },
  {
    path: "/complete/:phoneNumber?/:maxicareId?",
    Component: CommunicationMethodSelection
  },
  {
    path: "/doctorsNote",
    Component: DoctorsNote,
  },
]);