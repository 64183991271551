import { Flex } from '@chakra-ui/react';
import { memo } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Colors } from '../../constants';
import { useNavigate } from "react-router-dom";


interface BackButtonProps {
  onClick?: () => void
}

export const BackButton = memo<BackButtonProps>(({
  onClick
}) => {

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1)
  }

  return (
    <Flex
      onClick={onClick ?? goBack}
      cursor={'pointer'}
      p={3}
      ml={[0, "32px"]}
      mt="32px"
      borderRadius={8}
      width={'50px'}
      justifyItems={'center'}
      borderWidth={1}
      borderColor={Colors.darkestShade}>
      <ArrowBackIcon boxSize={5} color={Colors.darkestShade} />
    </Flex>
  );
});
