export const Colors = {
  primaryButton: '#256BF6',
  gray: '#F4F6F8',
  lightGreen: '#E1F7F3',
  darkGreen: '#207567',
  black: '#111824',
  selectOptionSelected: '#256BF640',

  primary: "#256BF6",
  primaryWithOpacity: {
    "08": "#256bf614",
  },
  primaryDark: "#0041C2",
  primaryLight: "#7198FF",
  success: "#27AE60",
  successWithOpacity: {
    "08": "#27AE6014",
  },
  warning: "#F3AD3D",
  warningWithOpacity: {
    "08": "#F3AD3D14",
  },
  danger: "#E05138",
  dangerDark: "#9a230e",
  dangerWithOpacity: {
    "08": "#E0513814",
  },
  emptyShade: "#FFFFFF",
  lightestShade: "#F4F6F8",
  lightShade: "#EEF0F2",
  mediumShade: "#D5D9DE",
  darkShade: "#A3A9B1",
  darkestShade: "#697481",
  fullShade: "#111824",
  lightBlue: "#E9F0FE",
  grey: "#19181A",

}

export const TextSizes = {
  small: '12px',
  medium: '14px',
  large: '16px',
  xlarge: '18px',
  xxlarge: '20px',
  xxxlarge: '24px',
}

const IS_PRODUCTION = () => {
  if (process.env?.TARGET_IS_PRODUCTION) return true;
  return !/staging|localhost/i.test(window.location.href);
};

export const Variables = {
  DOMAIN_URL: 'https://hippocrates.staging.botmd.io',
  URL: 'https://hippocrates.staging.botmd.io/gql/',
  providerApplicationUid: 'jnPSqU973SBX4QDbmhKle4InpdReJteh',
  clinicDomain: sessionStorage.getItem('clinicDomain'),
  doctorsOrderClinicalParamUid: !IS_PRODUCTION() ? 'vKvh6vSQZkUsXiA1T0MbB8UfdwFGa6Cf' : '',
  doctorsNoteUploadIdSessionKey: 'doctorsNoteUploadId',
}

export const SESSION_KEYS = {
  doctorsNoteUploadIdSessionKey: 'doctorsNoteUploadId',
  session: 'session',
}

export enum QueryKey {
  HeimdallSession = 'HeimdallSession',
  EventTypes = 'EventTypes',
  EventCalendars = 'EventCalendars',
  UpdateCommunicationMethod = 'UpdateCommunicationMethod',
  UploadStitch = 'UploadStitch',
  Patient = 'Patient',
  PatientEvent = 'PatientEvent',
  PatientEvents = 'PatientEvents',
  EventCalendar = 'EventCalendar',
  Clinics = 'Clinics',
  MetadataEntryCreate = 'MetadataEntryCreate',
}