import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Header } from '../../components';
import { useParams } from 'react-router-dom';
// import { DoctorsNote } from '../doctorsNote/doctorsNote';
import { CommunicationMethodSelection } from '../CommunicationMethodSelection/CommunicationMethodSelection';

export const OnlineBooking = () => {
  const { clinicDomain } = useParams()

  // const redirectToEventSelect = useCallback((hasDoctorsNote: boolean) => {
  //   // if There is a session id, redirect to appointment selection
  //   window.location.href = `/appointmentSelection/${maxicareId}/${hasDoctorsNote}`
  // }, [maxicareId])

  useEffect(() => {
    sessionStorage.removeItem('starttime')
  }, [])

  return (
    <Flex bg="#F4F6F8" width={'100%'} flexDirection={'column'}>
      <Header clinicDomain={clinicDomain} />

      <CommunicationMethodSelection />
      {/* <DoctorsNote
        onSelect={(hasdoctorsNode) => {
          redirectToEventSelect(hasdoctorsNode)
        }}
      /> */}
    </Flex>
  );
};


