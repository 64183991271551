import { Box, Flex, Text } from '@chakra-ui/react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useGetPatient } from '../../page/CommunicationMethodSelection/hooks';
import { capitalize, get } from 'lodash';
import { CommunicationMethod, Patient } from '../../page';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../selectOption/selectOption';
import { Colors } from '../../constants';
import { Loading } from '../loading/loading';

interface ComMethodSelectProps {
  onClick: (selected: string) => void
}

export const ComMethodSelect = memo<ComMethodSelectProps>(({
  onClick,
}) => {

  const [loading, setLoading] = useState(true)

  const { maxicareId } = useParams()

  const { data: patientData } = useGetPatient({
    enabled: true,
  })

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const patient = useMemo(() => {
    if (!patientData) return null
    return get(patientData?.response, 'cleoPatient', null) as unknown as Patient
  }, [patientData])

  console.log('patient', patient)

  if (patient?.isDeviceCreated && patient?.communicationMethod !== CommunicationMethod.SMS) {
    window.location.href = `/appointmentSelection/${maxicareId}`
  }

  const options = [
    'MESSENGER',
    'VIBER',
  ]

  if (loading) {
    return (
      <Loading text='loading...' />
    )
  }

  return (
    <Flex
      flex={1}
      ml="40px"
      mr="40px"
      height={'100%'}
      flexDirection={'column'}
      borderRadius={'8px'}
      bg="#FFFFFF"
      p={10}>

      <Flex justifyContent={'center'} flexDirection={'column'} overflowY='scroll' height={'100%'} >
        <Box mb={10}>
          <Text fontWeight={"600"} color={Colors.black} sx={{
            '@media screen and (max-width: 768px)': {
              fontSize: '24px',
            }
          }} fontSize={24}>{`Hi ${patient?.name ?? ''}! Select your preferred messaging channel`}
          </Text>

          <Text mr={4} color={Colors.black} fontSize={14}>
            We will send you appointment confirmation and reminders of your booking through chat
          </Text>

        </Box>
        <Box sx={{
          '@media screen and (max-width: 768px)': {
            width: '100%',
          }
        }} width={'60%'} height={'70%'} >
          {options.map((label, index) => {
            return (
              <SelectOption
                key={label}
                label={capitalize(label)}
                onClick={() => onClick(label)}
                style={{
                  padding: 10,
                }}
              />
            )
          })}
        </Box>

        <Box p={4} borderRadius={8} mt={10} bg={Colors.gray}>
          <Text color={Colors.black} fontSize={14}>
            By continuing, you consent to receive messages about your care through the Maxicare Viber Business Account or Facebook Messenger
          </Text>
        </Box>
      </Flex>

    </Flex>
  );
});
