import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

const GET_PATIENT_GQL = gql(`
  query cleoPatientWithSession {
    cleoPatient {
      uid
      name
      phone
      createdOn
      email
      communicationMethod
      isDeviceCreated
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type PatientEventsResult = {
  response?: unknown | GqlError
}


export const useGetPatient = (
  option: Option<PatientEventsResult, Error> = {}): UseQueryResult<PatientEventsResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getPatient = async (): Promise<PatientEventsResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_PATIENT_GQL,
        {},
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useQuery<PatientEventsResult, Error>({
    queryKey: [QueryKey.Patient],
    queryFn: () => getPatient(),
    cacheTime: 0,
    ...options,
  })


}