import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

const GET_CLINICS_GQL = gql(`
  query cleoClinicGroup {
    cleoClinicGroup {
      uid
      name
      clinics {
        name
        domain
      }
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

interface ClinicsResponse {
  uid: string
  name: string
  clinics: Array<{
    name: string
    domain: string
  }>
}

export type ClinicsResult = {
  response?: ClinicsResponse | GqlError
}


export const useGetClinics = (
  option: Option<ClinicsResult, Error> = {}): UseQueryResult<ClinicsResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getClinics = async (): Promise<ClinicsResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_CLINICS_GQL,
        {},
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result as ClinicsResponse
      };
    } catch (e) {
      return {
        response: null as unknown as GqlError
      };
    }
  }

  return useQuery<ClinicsResult, Error>({
    queryKey: [QueryKey.Clinics],
    queryFn: () => getClinics(),
    ...options,
  })
}