import { Icon } from "@chakra-ui/react"

export const UploadImage = ({ height = 35, width = 260, fill = "none" }) => {
  return (
    <Icon viewBox={`0 0 ${width} ${height}`} height={height} width={width}>
      <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.75 0H2.25C0.984375 0 0 1.03125 0 2.25V15.75C0 17.0156 0.984375 18 2.25 18H21.75C22.9688 18 24 17.0156 24 15.75V2.25C24 1.03125 22.9688 0 21.75 0ZM21.4688 15.75H2.53125C2.34375 15.75 2.25 15.6562 2.25 15.4688V2.53125C2.25 2.39062 2.34375 2.25 2.53125 2.25H21.4688C21.6094 2.25 21.75 2.39062 21.75 2.53125V15.4688C21.75 15.6562 21.6094 15.75 21.4688 15.75ZM6 4.125C4.92188 4.125 4.125 4.96875 4.125 6C4.125 7.07812 4.92188 7.875 6 7.875C7.03125 7.875 7.875 7.07812 7.875 6C7.875 4.96875 7.03125 4.125 6 4.125ZM4.5 13.5H19.5V9.75L15.375 5.67188C15.1406 5.4375 14.8125 5.4375 14.5781 5.67188L9 11.25L7.125 9.42188C6.89062 9.1875 6.5625 9.1875 6.32812 9.42188L4.5 11.25V13.5Z" fill="#256BF7" />
      </svg>
    </Icon>
  )
}