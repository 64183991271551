import { Flex, Button } from '@chakra-ui/react';
import React, { memo, useMemo } from 'react';
import { Logo } from '../../assets/images';
import { RepeatIcon } from '@chakra-ui/icons';

interface HeaderProps {
  children?: React.ReactNode;
  maxicareId?: string;
  clinicDomain?: string | null;
  restartText?: string;
  hideRestartIcon?: boolean;
  leftIcon?: JSX.Element;
  restartIcon?: JSX.Element;
  onClick?: () => void;
}

export const Header = memo<HeaderProps>(({
  children,
  maxicareId,
  clinicDomain,
  restartText = "Start again",
  hideRestartIcon = false,
  leftIcon,
  restartIcon = <RepeatIcon />,
  onClick
}) => {

  const isMobile = useMemo(() => window.innerWidth < 768, []);

  const startAgain = () => {
    if (maxicareId) {
      if (onClick) {
        return onClick()
      }
      window.location.href = `/${maxicareId}`
    }
  }

  return (
    <Flex flexDirection={'column'} pr={isMobile ? 2 : 10} pl={isMobile ? 2 : 10}>
      <Flex
        height={'100'}
        alignItems={'center'}
        justifyItems={'center'}
        justifyContent={'center'}
      >
        <Flex flex={1} />
        <Flex justifyContent={'center'} flex={1}><Logo width={isMobile ? 120 : 200} /></Flex>
        <Flex sx={{
          '@media screen and (max-width: 768px)': {
            maxWidth: '130px',
          }
        }} justifyItems={'center'} flex={1} alignItems={'center'} justifyContent={'flex-end'}>

          {maxicareId && (
            <Button sx={{
              '@media screen and (max-width: 768px)': {
                fontSize: '14px',
                textWrap: 'wrap',
              }
            }} size={isMobile ? 'sm' : 'md'} onClick={startAgain} leftIcon={!hideRestartIcon ? (leftIcon || restartIcon) : undefined} colorScheme='messenger' variant='solid'>
              {restartText}
            </Button>
          )}

        </Flex>
      </Flex>
      {children}
    </Flex>
  )
})