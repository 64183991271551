//import liraries
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPatient, useUpdatePatientComMethod } from './hooks';
import { get } from 'lodash';
import { ComMethodSelect } from '../../components';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../constants';

export interface Patient {
  uid: string;
  communicationMethod: string;
  isDeviceCreated: boolean;
  phone: string;
  email: string;
  name: string;
}

export enum CommunicationMethod {
  SMS = 'SMS',
  MESSENGER = 'MESSENGER',
  VIBER = 'VIBER',
}

// create a component
export const CommunicationMethodSelection = () => {

  const { maxicareId } = useParams()

  const queryClient = useQueryClient()

  const { data: patientData, refetch } = useGetPatient({
    enabled: true,
  })

  const patient = useMemo(() => {
    if (!patientData) return null
    return get(patientData?.response, 'cleoPatient', null) as unknown as Patient
  }, [patientData])

  const { mutateAsync: updateCommunicationMethod } = useUpdatePatientComMethod({
    onSuccess: (data) => {
      console.log('success', data)
    }
  })

  // TODO: After appointment is created
  // TODO: Load patient profile from cleoPatient

  // IF Viber communication method show the success component

  // TODO: IF patient communication method is sms then show option component to choose viber or facebook messenger

  // TODO: if they choose Facebook messenger then update patient communication using a mutate 'cleoPatientUpdate' and move to next page

  // But if they come with a communication method already being Facebook and is isDevice created then show booking success component

  // But if they come with communication method set to Facebook and is isDevice is not created then show the QR code




  const onCommunicationMethodSelect = async (communicationMethod: string) => {
    if (patient) {
      await updateCommunicationMethod({ communicationMethod, uid: patient.uid as string })
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.Patient, patient.uid]
      })
      await refetch()
      window.location.href = `/appointmentSelection/${maxicareId}`
    }

    // if (communicationMethod === CommunicationMethod.VIBER) {
    //   window.location.href = '/success'
    // }

    // if (communicationMethod === CommunicationMethod.MESSENGER && patient?.isDeviceCreated === false) {
    //   //patientPhone, maxicareId, isDeviceCreated
    //   window.location.href = `/success/${patient?.phone}/${maxicareId}/${patient?.isDeviceCreated}`
    // }

  }

  return (
    <ComMethodSelect onClick={onCommunicationMethodSelect} />
  );
};


