import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';


const GET_EVENT_TYPES_SQL = gql(`
  query cleoClinicCalendarEventType ($nameIcontains: String) {
    cleoClinicCalendarEventTypes (eventType_Name_Icontains: $nameIcontains, eventType_DeactivatedOn_Isnull: true) {
      edges {
        node {
          eventType {
            uid
            name
          }
        }
      }
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type EventTypesResult = {
  response?: unknown | GqlError
}

interface Param {
  nameIcontains?: string
}


export const useGetEventTypes = (
  param?: Param,
  option: Option<EventTypesResult, Error> = {}): UseQueryResult<EventTypesResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getEventTypes = async (): Promise<EventTypesResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_EVENT_TYPES_SQL,
        {
          nameIcontains: param?.nameIcontains
        },
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useQuery<EventTypesResult, Error>({
    queryKey: [QueryKey.EventTypes],
    queryFn: () => getEventTypes(),
    ...options,
  })


}
