import {
  Box, Button, Flex, Text, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tag,
} from '@chakra-ui/react';
import Select, { OptionProps } from 'react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BackButton, DoctorSelect, Header } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetEventCalendars, useGetPatientEventsCount } from './hooks';
import { flatMap, get, isEmpty } from 'lodash';
import { useGetPatient } from '../CommunicationMethodSelection/hooks';
import { Patient } from '../CommunicationMethodSelection/CommunicationMethodSelection';
import { SESSION_KEYS, Variables } from '../../constants';
import { CalendarIcon } from '@chakra-ui/icons';
import { usePatientEventMetadataEntryCreate } from '../doctorsNote/hooks/usePatientEventMetadataEntryCreate';

export interface DoctorCalendar {
  uid: string;
  name: string;
  timezone: string;
  id: string;
}

let interval: NodeJS.Timer;

type DropdownTypes = 'LOCATION' | 'DOCTOR';

export const Calendar = () => {

  const { isOpen, onClose } = useDisclosure()

  const { id: eventTypeId, maxicareId } = useParams()

  // const urlParams = new URLSearchParams(window.location.search);

  const { data: doctorsCalendars } = useGetEventCalendars([eventTypeId as string], {
    enabled: !!eventTypeId,
  })


  const { data: patientData } = useGetPatient({
    enabled: true,
  })

  const patient = useMemo(() => {
    if (!patientData) return null
    return get(patientData?.response, 'cleoPatient', null) as unknown as Patient
  }, [patientData])


  /* Old patients are allowed to choose a doctor but new patients NO */
  // check if patient createdOn date is greater than 1 hour then its an old patient
  // const isOldPatient = useMemo(() => {
  //   if (!patient) return false
  //   const createdOn = get(patient, 'createdOn', '')
  //   const now = moment()
  //   const createdOnMoment = moment(createdOn)
  //   const diff = now.diff(createdOnMoment, 'hours')
  //   console.log({ diff })
  //   if (diff > 1) return true
  //   return false
  // }, [patient])

  // console.log({ isOldPatient })


  const startTime = useMemo(() => {
    return sessionStorage.getItem('starttime')
  }, [])

  const { data: patientEvents, refetch } = useGetPatientEventsCount(patient?.uid as string, startTime as string, {
    enabled: !isEmpty(patient) && !isEmpty(startTime),
  })

  const { mutateAsync: uploadDoctorsNote, isLoading: uploadingDoctorsNote } = usePatientEventMetadataEntryCreate()

  const patientEventsCount = useMemo(() => {
    if (!patientEvents) return 0
    return get(patientEvents, 'response.cleoPatient.patientEvents.totalCount', 0)
  }, [patientEvents])

  // Get latest event uid
  const latestEventUid = useMemo(() => {
    if (!patientEvents) return ''
    const edges = get(patientEvents, 'response.cleoPatient.patientEvents.edges', [])
    const latestEvent = edges[0]
    return get(latestEvent, 'node.uid', '')
  }, [patientEvents])

  console.log({ latestEventUid })

  // TODO: Make sure interval is cleared
  const clearTimeInterval = async () => {
    clearInterval(interval)

  }

  const uploadDoctorsNoteHandler = async () => {
    const uploadId = sessionStorage.getItem(SESSION_KEYS.doctorsNoteUploadIdSessionKey)
    if (uploadId && !uploadingDoctorsNote) {
      const result = await uploadDoctorsNote({
        parameter: {
          uid: Variables.doctorsOrderClinicalParamUid,
        },
        patientEvent: {
          uid: latestEventUid,
        },
        value: {
          file: {
            url: `stitch://${uploadId}`,
            name: 'doctors-note',
            suffix: '',
          }
        },
      })

      console.log({ result })
    }
  }

  useEffect(() => {
    if (!interval) {
      interval = setInterval(() => {
        if (sessionStorage.getItem('starttime')) {
          refetch()
        }
      }, 5000);
    }

    if (patientEventsCount > 0) {
      uploadDoctorsNoteHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEventsCount])

  console.log({ patientEventsCount })

  const doctorsList = useMemo(() => {
    if (!doctorsCalendars) return []
    const result = flatMap(get(doctorsCalendars, 'response.cleoClinicCalendars.edges'), (cal) => {
      const metadata = get(cal, 'node.metadata.maxicare') as unknown as DoctorCalendar
      return {
        ...metadata,
        uid: get(cal, 'node.uid') as unknown as string,
      }
    })
    return result
  }, [doctorsCalendars])


  const phoneNumber = patient?.phone ?? "";
  const firstName = patient?.name ?? "";
  const lastName = "-";
  const email = patient?.email ?? "";
  const navigate = useNavigate();
  // const clinic = urlParams.get('clinic') ?? ""; // TO show the slots for the clinic

  const [selectedDoctor, setSelectedDoctor] = useState<DoctorCalendar>()

  const [showCalendarSelection, setShowCalendarSelection] = useState(false)

  const [selectedDropdown, setSelectedDropdown] = useState<DropdownTypes | null>()

  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string; } | null>(null);


  const goBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  interface IOption {
    readonly value: string;
    readonly label: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
  }

  const options: IOption[] = useMemo(() => {
    return [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ];
  }, []);


  console.log({ patient })

  const handleComplete = () => {
    clearTimeInterval()
    console.log('patient calendar', patient?.isDeviceCreated)
    sessionStorage.removeItem('starttime')
    window.location.href = `/success/${phoneNumber}/${maxicareId}/${patient?.isDeviceCreated}`
  }

  const Option = useCallback((props: OptionProps<IOption>) => {
    return (
      <Box onClick={(e) => {
        setSelectedOption(props.data)
        // props.selectOption(props.data)
        // props.setValue(props.data, 'select-option')
        setSelectedDropdown(null)
      }} p={2} mt={-1} _hover={{
        backgroundColor: '#F4F6F8',
      }}>
        {/* <components.Option {...props} /> */}
        <Text>{props.data.value}</Text>
        <Tag size={'sm'} variant='solid' textColor={'#27AE60'} bgColor='green.100'>
          {props.data.label}
        </Tag>
      </Box>
    );
  }, []);



  const locationsModal = useMemo(() => {
    return (
      <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textColor={'black'}>Check other locations</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text mb={2} color={'gray'} fontSize={12} fontWeight={'600'}>Clinic location</Text>
              <Select
                onFocus={() => {
                  setSelectedDropdown('LOCATION')

                }}
                menuIsOpen={selectedDropdown === 'LOCATION'}
                onMenuOpen={() => {
                  setSelectedDropdown('LOCATION')
                }}
                onMenuClose={() => {
                  setSelectedDropdown('' as DropdownTypes)
                }}
                closeMenuOnSelect={false}
                components={{ Option }}
                styles={{
                  option: (base) => ({
                    ...base,
                    height: '100%',
                  }),
                }}
                value={selectedOption}
                options={options}
              />
            </Box>
            <Box mt={4}>
              <Text mb={2} color={'gray'} fontSize={12} fontWeight={'600'}>Select doctor</Text>
              <Select
                onFocus={() => {
                  setSelectedDropdown('DOCTOR')
                }}
                menuIsOpen={selectedDropdown === 'DOCTOR'}
                onMenuOpen={() => {
                  setSelectedDropdown('DOCTOR')
                }}
                onMenuClose={() => {
                  setSelectedDropdown('' as DropdownTypes)
                }}
                closeMenuOnSelect={false}
                components={{ Option }}
                styles={{
                  option: (base) => ({
                    ...base,
                    height: '100%',
                  }),
                }}
                options={options}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant='outline' textColor={'#697481'} colorScheme='#697481' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button bg={'#256BF6'} textColor={'white'} onClick={onClose}>Schedule appointment</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }, [Option, isOpen, onClose, options, selectedDropdown, selectedOption])


  const onDoctorSelected = useCallback((selectedDoctor: DoctorCalendar) => {
    console.log('onDoctorSelected', { selectedDoctor })
    sessionStorage.setItem('starttime', new Date().toISOString().replace('Z', '+00:00'))
    setSelectedDoctor(selectedDoctor)
    setTimeout(() => {
      setShowCalendarSelection(true)
    }, 500);
  }, [])


  // Automatically select the first doctor if the patient is new
  // useEffect(() => {
  //   if (!isOldPatient && doctorsList.length > 0) {
  //     const pickedCalendar = doctorsList[0]
  //     console.log({ pickedCalendar })
  //     onDoctorSelected(pickedCalendar)
  //   }
  // }, [doctorsList, isOldPatient, onDoctorSelected])


  // const gotoReminders = useCallback(() => {
  //   window.location.href = `/${maxicareId}`
  // }, [maxicareId])

  const onBackButtonPressed = useCallback(() => {
    console.log({ showCalendarSelection, selectedDoctor })
    if (showCalendarSelection) {
      setSelectedDoctor(undefined)
      return setShowCalendarSelection(false)
    }

    goBack()

  }, [goBack, selectedDoctor, showCalendarSelection])

  return (
    <Flex
      bg="#F4F6F8"
      flexDirection={'column'}
      height={'100%'}
    >
      {locationsModal}
      <Header />

      <Flex
        ml={[0, "40px"]}
        mr={[0, "40px"]}
        flexDirection={'column'} bg={'white'} pl={4} pt={4} height={'100%'} borderRadius={'8'}>

        {patientEventsCount === 0 && (<BackButton onClick={onBackButtonPressed} />)}

        {showCalendarSelection && (<>
          <Flex mb={4} width={'98%'} justifyContent={'flex-end'}>
            {/* <Button onClick={onOpen} colorScheme='gray' fontSize="14" textColor={'gray'} variant='outline'>
              Check other locations
            </Button> */}

            {patientEventsCount > 0 && (<>
              <Button leftIcon={<CalendarIcon />} onClick={handleComplete} mr={4} colorScheme='messenger' fontSize="14" textColor={'white'} >
                Next: Reminders
              </Button>
            </>)}
          </Flex>
          <Box width={'98%'} h="100%">

            <iframe style={{ width: '100%', height: '100%', display: 'flex', alignSelf: 'center', margin: '0 auto' }} title='main'
              src={`https://botmd.as.me/schedule.php?email=${email}&phone=${phoneNumber}&firstName=${firstName}&lastName=${lastName}&field:13451381=${maxicareId}&calendarID=${selectedDoctor?.id}`} />
          </Box>
        </>)}

        {!showCalendarSelection && (
          <DoctorSelect doctorsList={doctorsList} onClick={onDoctorSelected} />
        )}

      </Flex>

    </Flex>
  );
};


