import { Box, Flex, Text } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { BackButton, Header, Loading, SelectOption } from '../../components';
import { useGetEventTypes } from './hooks';
import { flatMap, get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Variables } from '../../constants';
import { DoctorsNote } from '../doctorsNote/doctorsNote';

interface Event {
  name: string;
  uid: string;
}

export const AppointmentSelect = () => {

  const navigate = useNavigate()
  const { id: maxicareId } = useParams()

  const [selectedEvent, setSelectedEvent] = useState<Event>()
  const [showDoctorsNote, setShowDoctorsNote] = useState(false)


  const { data: events, isLoading } = useGetEventTypes({
    // nameIcontains: hasDoctorsOrder ? undefined : 'consultation',
  }, {


  })

  const clinicDomain = Variables.clinicDomain

  console.log({ events })

  const eventsList = useMemo(() => {
    if (!events) return []
    const result = flatMap(get(events, 'response.cleoClinicCalendarEventTypes.edges'), (event) => {
      return get(event, 'node.eventType') as unknown as Event
    })
    return result
  }, [events])


  const gotoCalendar = useCallback((id: string) => {
    window.location.href = `/calendar/${id}/${maxicareId}`
  }, [maxicareId])

  const onSelectCalendar = useCallback((event: Event) => {
    setSelectedEvent(event)
    if (event.name.includes('Consultation')) {
      gotoCalendar(event.uid)
    } else {
      // Show Doctors Note Page
      setShowDoctorsNote(true)
    }
  }, [gotoCalendar])



  return (
    <Flex bg="#F4F6F8" h="98vh" flexDirection={'column'}>

      <Header clinicDomain={clinicDomain} />
      {isLoading && <Loading text='loading...' />}


      {!isLoading && !showDoctorsNote && (
        <Flex
          ml={[0, "40px"]}
          mr={[0, "40px"]}
          flex={1}
          flexDirection={'column'}
          borderRadius={'8px'}
          bg="#FFFFFF"
          p={4}>

          <BackButton onClick={() => {
            navigate(`/${maxicareId}`)
          }} />

          <Flex height={'100%'} justifyContent={'center'} pt={20} pb={10}>

            <Flex flexDirection={'column'}>
              <Text sx={{
                '@media screen and (max-width: 768px)': {
                  fontSize: '24px',
                }
              }} fontSize={28} mb={4}>What would you like to book today? *</Text>
              <Box>
                {eventsList.map((ev, index) => {
                  if (!ev) return null
                  return (
                    <SelectOption
                      key={ev.uid}
                      index={index + 1}
                      label={ev.name}
                      onClick={() => {
                        onSelectCalendar(ev)
                      }}
                    />
                  )
                })}
              </Box>
            </Flex>
          </Flex>

        </Flex>
      )}

      {showDoctorsNote && (
        <DoctorsNote
          onSelect={(hasdoctorsNode) => {
            gotoCalendar(selectedEvent?.uid as string)
          }}
        />
      )}

    </Flex>
  );
};
