import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
// import { ChakraProviderWrapper } from "@fivehealth/botero-ts"
import { Box, ChakraProvider, Flex } from '@chakra-ui/react'
import { PoweredBy } from './assets/images';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Colors } from './constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60,
      cacheTime: 60,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <Flex bg={Colors.gray} width={'100%'} height={'100vh'} flexDirection={'column'} mb={'100px'}>
          <RouterProvider router={router} />
          <Flex
            position={'fixed'}
            bottom={0}
            width={'100%'}
            flex={1}
            margin={'0 auto'}
            bg={"#F4F6F8"}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box>
              <PoweredBy height={45} width={300} />
            </Box>
          </Flex>
        </Flex>
      </ChakraProvider>
    </QueryClientProvider>


  );
}

export default App;
