//import liraries
import { Badge, Box, Flex, Text, Button, Input, Heading } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorModal, Header, LoadingSpinner } from '../../components';
import { Colors } from '../../constants';
import { useGetPatientEvents } from './hooks';
import { flatMap, get } from 'lodash';
import { BotDoctor, LocationPin, Doctor, Calendar } from '../../assets/images';
import { useParams } from 'react-router-dom';
import { useHeimdallSession } from '../onlineBooking/hooks';
import { isEmpty } from 'lodash';
import { AddIcon } from '@chakra-ui/icons';

export interface Event {
  uid: string;
  createdOn: string;
  event: {
    name: string;
    providerEventType: string;
  }
  patient: {
    name: string;
  }
  calendar: {
    name: string;
    uid: string;
  }
  isConfirmed: boolean;
  isPatientShowed: boolean;
  startOn: string;
  status: string;
  eventUrl: string;
}


// create a component
export const Main = () => {

  const { maxicareId, clinicDomain, dob } = useParams()
  const [submitting, setSubmitting] = useState(false)
  const [enteredMaxicareId, setEnteredMaxicareId] = useState(maxicareId ?? '')
  const [selectedClinic, /* setSelectedClinic */] = useState(clinicDomain ?? 'maxicare-ayala-north')
  const [showError, setShowError] = useState(false)
  const [errorMessages, setErrorMessages] = useState('')

  const parsedDob = useMemo(() => {
    if (dob) {
      const [year, month, day] = dob.split('-')
      return { year, month, day }
    }
    return { year: '', month: '', day: '' }
  }, [dob])

  const [dobDay, setDobDay] = useState(parsedDob.day)
  const [dobMonth, setDobMonth] = useState(parsedDob.month)
  const [dobYear, setDobYear] = useState(parsedDob.year)

  const dobDayRef = React.useRef<HTMLInputElement>(null)
  const dobMonthRef = React.useRef<HTMLInputElement>(null)
  const dobYearRef = React.useRef<HTMLInputElement>(null)

  const [step, setStep] = useState(1)

  console.log({ maxicareId })

  // const { } = useHeimdallSession()

  // const { data: clinics } = useGetClinics({
  //   enabled: !clinicDomain && maxicareId,
  // })

  const dobEnabled = useMemo(() => {
    if (dobDay.length === 2) return true
    if (dobMonth.length === 2) return true
    if (dobYear.length === 4) return true
    return false
  }, [dobDay.length, dobMonth.length, dobYear.length])

  console.log({ dobEnabled })

  const { data: sessionData, isFetching: loadingSession } =
    useHeimdallSession(enteredMaxicareId, selectedClinic, `${dobYear}-${dobMonth}-${dobDay}`,
      {
        enabled: dobEnabled && !isEmpty(selectedClinic) && enteredMaxicareId.length === 16 && submitting,
        staleTime: 1000 * 60 * 5,
      },
    )

  const sessionId = useMemo(() => {
    return get(sessionData, 'response.heimdallAuthorizationFlow.session.uid', sessionStorage.getItem('session') ?? '')
  }, [sessionData])

  useEffect(() => {
    const strErr = get(sessionData,
      'response.response.errors[0].message',
      "")
    if (strErr) {
      setErrorMessages(strErr)
    }

    if (sessionData && !strErr) {
      setSubmitting(false)
    }
  }, [sessionData])

  const showEvents = useMemo(() => {
    if (sessionId && enteredMaxicareId && enteredMaxicareId.length === 16) return true
    return false
  }, [enteredMaxicareId, sessionId])

  useEffect(() => {
    sessionStorage.removeItem('session')
    if (sessionId) {
      sessionStorage.setItem('session', sessionId)
    }
  }, [sessionId])

  console.log({ maxicareId, clinicDomain, sessionId })

  const { data: eventsData, isFetching: loadingEvents } = useGetPatientEvents({
    enabled: !isEmpty(sessionId) && enteredMaxicareId.length === 16,
    cacheTime: 1000 * 60 * 5,
  })

  console.log({ eventsData, loadingEvents })

  // const { data: patientData } = useGetPatient({
  //   enabled: !!sessionId,
  // })

  // const patient = useMemo(() => {
  //   if (!patientData) return null
  //   return get(patientData?.response, 'cleoPatient', null) as unknown as Patient
  // }, [patientData])

  // console.log({ patient })

  // console.log({ loadingEvents })

  // // check if patient createdOn date is greater than 1 hour then its an old patient, use momentjs
  // const isOldPatient = useMemo(() => {
  //   if (!patient) return false
  //   const createdOn = get(patient, 'createdOn', '')
  //   const now = moment()
  //   const createdOnMoment = moment(createdOn)
  //   const diff = now.diff(createdOnMoment, 'hours')
  //   console.log({ diff })
  //   if (diff > 1) return true
  //   return false
  // }, [patient])

  // console.log({ isOldPatient })


  const events = useMemo(() => {
    if (!eventsData) return []
    return flatMap(get(eventsData, 'response.cleoPatientEvents.edges'), (event) => {
      return get(event, 'node') as unknown as Event
    })
  }, [eventsData])

  console.log('errorMessages', errorMessages)


  const onSubmit = () => {
    try {
      console.log('step', step)
      if (step === 1) {
        setStep(2)
        return
      }
      if (enteredMaxicareId && selectedClinic && enteredMaxicareId.length === 16) {
        console.log('Set submitting to true')
        setSubmitting(true)


        // if (errorMessages) {
        //   refetchSession()
        // }
      }
    } catch (error) {

    }
  }

  const newAppointment = () => {
    sessionStorage.setItem('clinicDomain', selectedClinic)
    window.location.href = `/onlineBooking/${enteredMaxicareId}`
  }

  useEffect(() => {
    if (maxicareId && clinicDomain && maxicareId.length === 16) {
      sessionStorage.setItem('clinicDomain', clinicDomain)
      window.location.href = `/onlineBooking/${clinicDomain}/${maxicareId}`
    }

  }, [clinicDomain, enteredMaxicareId, maxicareId, selectedClinic])

  useEffect(() => {
    if (errorMessages && !showEvents) {
      setSubmitting(false)
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [errorMessages, showEvents])


  const renderApptSection = (label: string, section: "location" | "date" | "name") => {

    const iconSection = {
      location: <LocationPin />,
      date: <Calendar />,
      name: <Doctor />,
    }

    return (
      <Flex>
        <Box mr={-4}>{iconSection[section]}</Box>
        <Text ml={2} fontSize={"md"}>{label}</Text>
      </Flex>
    )
  }

  const onEditClicked = (url: string) => {
    window.open(url, '_blank')
  }

  const renderAppointment = (event: Event) => {
    const location = event.event.providerEventType.split(':')[1]
    const eventName = event.event.name.split(':')[0]
    return (
      <Box key={event.uid} mt={4} p={4} borderRadius={"8px"} borderWidth={1} >
        <Flex
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Flex flexWrap={'wrap'} flex={0.9}>
            <Badge style={{
              // @ts-ignore
              textWrap: 'wrap',
            }} p={2} borderRadius={8} bg={Colors.lightGreen} mb={1} fontSize={14} color={Colors.darkGreen}>
              {eventName ?? event.event.name}
            </Badge>
          </Flex>
          <Flex cursor={'pointer'} onClick={() => {
            onEditClicked(event.eventUrl)
          }}>
            <Text fontWeight={'600'} color={Colors.primary} fontSize={14}>Edit</Text>
          </Flex>
        </Flex>
        {renderApptSection(location ?? event.event.providerEventType, "location")}
        {renderApptSection(event.startOn, "date")}
        {renderApptSection(event.calendar.name, "name")}
      </Box>
    )
  }

  const btnDisabled = useMemo(() => {
    // Check that dob is valid
    if (enteredMaxicareId.length !== 16 || !dobEnabled) return true
    if (step === 1) return false

    return false
  }, [dobEnabled, enteredMaxicareId.length, step])

  const divider = useMemo(() => {
    return (<Text mt={4} ml={4} mr={4} style={{
      fontSize: '24px',
      alignSelf: 'center'
    }} fontWeight={"600"} color={Colors.primaryButton}>
      /
    </Text>)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dobEntry = useMemo(() => {
    return (
      <Box>
        <Flex mt={3} flexDirection={'row'} alignItems={'center'} >
          <Box>
            <Text fontWeight={"400"} color={Colors.primaryButton}>Day</Text>
            <Input fontSize={24} ref={dobDayRef} type='number' mr={2} variant='flushed' style={{
              borderBottom: '1px solid blue.500',
            }} _placeholder={{
              color: 'blue.100',
              fontWeight: '500',
              fontSize: '24px',
            }}
              autoFocus
              size={"lg"}
              placeholder="DD"
              value={dobDay}
              onChange={(e) => {
                setDobDay(e.target.value)
                if (e.target.value.length === 2) {
                  dobMonthRef.current?.focus()
                }
              }} />
          </Box>
          {divider}
          <Box>
            <Text fontWeight={"400"} color={Colors.primaryButton}>Month</Text>
            <Input fontSize={24} ref={dobMonthRef} type='number' mr={2} variant='flushed' style={{
              borderBottom: '1px solid blue.500',
            }} _placeholder={{
              color: 'blue.100',
              fontWeight: '500',
              fontSize: '24px',
            }}
              size={"lg"}
              placeholder="MM"
              value={dobMonth}
              onChange={(e) => {
                setDobMonth(e.target.value)
                if (e.target.value.length === 2) {
                  dobYearRef.current?.focus()
                }
              }} />
          </Box>
          {divider}
          <Box>
            <Text fontWeight={"400"} color={Colors.primaryButton}>Year</Text>
            <Input fontSize={24} ref={dobYearRef} type='number' variant='flushed' style={{
              borderBottom: '1px solid blue.500',
            }} _placeholder={{
              color: 'blue.100',
              fontWeight: '500',
              fontSize: '24px',
            }}
              size={"lg"}
              placeholder="YYYY"
              value={dobYear}
              onChange={(e) => {
                setDobYear(e.target.value)
              }} />
          </Box>
        </Flex>
      </Box>
    )
  }, [divider, dobDay, dobMonth, dobYear])


  return (
    <Flex bg="#F4F6F8" flexDirection={'column'} flex={1}>

      {events.length > 0 && (
        <Header
          onClick={newAppointment}
          leftIcon={<AddIcon />}
          restartText='Appointment'
          maxicareId={enteredMaxicareId} clinicDomain={selectedClinic}
        />
      )}

      {!isEmpty(enteredMaxicareId) && (loadingEvents || submitting || loadingSession) && (
        <LoadingSpinner text='Loading your appointments...' />
      )}

      {(showError) && (
        <ErrorModal onClose={() => {
          setShowError(false)
          setSubmitting(false)
          setErrorMessages('')
        }} />
      )}

      {(!loadingEvents && !loadingSession) && sessionId && (
        <Flex
          flex={1}
          height={"100%"}
          width={["100%", "80%", "50%"]}
          flexDirection={"column"}
          p={[2, 10]}
          alignSelf={'center'}
          borderRadius={"8px"}
          bg="#FFF"
          mb={10}

        >

          <Heading as='h4' size='md' fontSize={16} pt={[3, 0, 0]} color={Colors.fullShade}>
            Your appointments
          </Heading>

          {events.map((event) => {
            return renderAppointment(event)
          })}


          {events.length === 0 && !loadingEvents && (
            <>

              <Flex
                height={"30%"}
                borderRadius={"8px"}
                border={'1px solid #E2E8F0'}
                width={"100%"}
                flexDirection={"column"}
                justifyContent={'center'}
                alignItems={'center'}
                mt={4}
                mb={4}
              >
                <BotDoctor height={20} width={20} />
                <Text align={'center'} fontSize={"lg"}>You have no upcoming appointments</Text>
              </Flex>

              <Button
                onClick={newAppointment}
                size={'md'} colorScheme='messenger' variant='solid'>
                Book appointment
              </Button>
            </>

          )}
        </Flex>
      )}

      {/* Show enter maxicare id, when its an online flow with no events */}
      {events.length === 0 && /* isEmpty(maxicareId) && */ !submitting && !sessionId && (
        <Flex bg="#FFFFFF" justifyItems={'center'}
          alignContent={'center'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          height={'100%'}>

          <Flex width={'40%'} sx={{
            '@media screen and (max-width: 768px)': {
              width: '95%'
            }
          }} flexDirection={'column'} alignSelf={'center'}>
            <Box borderRadius={'8px'}
            >
              <Text mb={6} fontSize={"2xl"}>
                {step === 1 ? "Enter your Maxicare ID Number" : "Enter your Date of Birth"}
              </Text>


              {step === 1 && (<Input fontSize={24} type='number' variant='flushed' style={{
                borderBottom: '1px solid blue.500',
              }} _placeholder={{
                color: 'blue.500',
                fontWeight: '500',
                fontSize: '24px',
              }} autoFocus size={"lg"} placeholder="Enter 16-digit card number" value={enteredMaxicareId} onChange={(e) => {
                console.log('e.target.value', e.target.value)
                setEnteredMaxicareId(e.target.value)
              }} />)}

              {step === 2 && dobEntry}
            </Box>

            <Button isDisabled={step === 1 ? !(enteredMaxicareId.length === 16) : btnDisabled} mt={6} colorScheme="messenger" size={"lg"} onClick={onSubmit}>
              {step === 1 ? "Next" : "Submit"}
            </Button>
          </Flex>
        </Flex>)}
    </Flex >

  );
};

