import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

const GET_EVENT_TYPES_SQL = gql(`
  query cleoPatientWithUid ($uid: String, $createdOn_Gte: DateTime) {
    cleoPatient(uid: $uid) {
      patientEvents (createdOn_Gte: $createdOn_Gte) {
        totalCount
        edges {
          node {
            uid
          }
        }
      }
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type PatientEventsResult = {
  response?: unknown | GqlError
}


export const useGetPatientEventsCount = (
  patientUid: string,
  startTime: string,
  option: Option<PatientEventsResult, Error> = {}): UseQueryResult<PatientEventsResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getPatientEvents = async (): Promise<PatientEventsResult> => {
    try {
      console.log('time', sessionStorage.getItem('starttime'))

      const result = await request(
        Variables.URL,
        GET_EVENT_TYPES_SQL,
        { uid: patientUid, createdOn_Gte: sessionStorage.getItem('starttime') },
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useQuery<PatientEventsResult, Error>({
    queryKey: [QueryKey.PatientEvent],
    queryFn: () => getPatientEvents(),
    ...options,
  })


}