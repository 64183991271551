import { Box, Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { BackButton, SelectOption } from '../../components';
import { UploadImage } from '../../assets/images';
import { Colors, SESSION_KEYS } from '../../constants';
import { memo, useRef, useState } from 'react';
import { useCreateStitchUpload } from './hooks/useCreateStitchUpload';
import { map } from 'lodash';

interface DoctorsNoteProps {
  onSelect?: (hasDoctorsOrder: boolean) => void
}

export const DoctorsNote = memo<DoctorsNoteProps>(({
  onSelect,
}) => {

  const fileRef = useRef<HTMLInputElement>(null);

  const [showUploadFile, setShowUploadFile] = useState(false)

  const toast = useToast()

  const { mutateAsync: uploadFileToStitch } = useCreateStitchUpload()

  const [fileUploaded, setFileUploaded] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [selectedFile, setSelectedFile] = useState<File>()

  const selectFile = () => {
    fileRef.current?.click();
  }

  const handleFileUpload = async () => {
    try {
      setIsLoading(true)
      if (fileRef.current?.files) {
        const file = fileRef.current?.files[0];
        console.log({ file })
        setSelectedFile(file)
        if (file) {

          const resultStitch = await uploadFileToStitch({
            key: 'cleo',
            mimeType: file.type,
          }).then((res) => res)

          console.log({ resultStitch })

          const { data: { stitchCreateUploadUrl } } = resultStitch
          const { uploadId, url, fields } = stitchCreateUploadUrl

          if (uploadId && url) {
            const body = new FormData();
            map(fields, (value, key) => {
              body.append(key, value);
            });
            body.append('file', file);
            const resultUpload = await fetch(url, {
              method: 'post',
              body,
            });

            console.log({ resultUpload })
            sessionStorage.setItem(SESSION_KEYS.doctorsNoteUploadIdSessionKey, uploadId)
            setFileUploaded(true)
          }
        }
      }
    } catch (error) {
      toast({
        title: `Error uploading file`,
        status: 'error',
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }

  }


  return (
    <Flex
      flex={1}
      ml={[0, "40px"]}
      mr={[0, "40px"]}
      flexDirection={'column'}
      borderRadius={'8px'}
      bg="#FFFFFF"
      p={4}>

      <BackButton />

      <Flex height={'100%'} justifyContent={'center'} pt={20}>

        <Flex flexDirection={'column'} width={'40%'} sx={{
          '@media screen and (max-width: 768px)': {
            width: '100%',
          },
          '@media screen and (min-width:501px) and (max-width:999px)': {
            width: '100%',
          }

        }}>
          <Text sx={{
            '@media screen and (max-width: 768px)': {
              fontSize: '24px',
            }
          }} fontSize={28} mb={4}>Do you have a doctor’s order?</Text>

          <Text fontSize={14} mb={4}>
            If you have a doctor’s order, please upload it here. If you don’t have one, you can skip this step.
          </Text>

          {!isLoading && (<Box>
            <SelectOption
              style={{ padding: '10px', background: showUploadFile ? Colors.selectOptionSelected : undefined }}
              label={'Yes'}
              onClick={() => {
                setShowUploadFile(true)
              }}
            />
            <Box mt={4}>
              <SelectOption
                style={{ padding: '10px' }}
                label={'No'}
                onClick={() => {
                  onSelect && onSelect(false)
                }}
              />
            </Box>
          </Box>)}


          {showUploadFile && (<Flex
            onClick={selectFile}
            cursor={'pointer'}
            mt={4}
            borderStyle={'dashed'}
            borderWidth={1.5}
            minHeight={'200px'}
            alignItems={'center'}
            borderRadius={8}
            bg={Colors.gray}
            flexDirection={'row'}
            justifyContent={'center'}
          >
            <Box >
              {isLoading && (
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
              )}
              {!isLoading &&
                (
                  <Flex flexDirection={'column'} alignItems={'center'}>
                    <Box textAlign={'center'}>
                      <UploadImage height={22} width={20} />
                      <Box>
                        <Text fontSize={14} color={Colors.primaryButton}>{selectedFile?.name || 'Upload file'}</Text>
                      </Box>
                    </Box>

                    {selectedFile && (<Button
                      onClick={() => {
                        setSelectedFile(undefined)
                      }}
                      border={`1px solid ${Colors.mediumShade}`}
                      bg="white"
                      color='gray.500'
                      mt={4}
                      fontWeight={'600'}
                    >
                      Replace file
                    </Button>)}
                  </Flex>
                )}
              {/* Create an input file hidden triggered by clicking the div */}
              <input ref={fileRef} type="file" id="file" onChange={handleFileUpload} style={{ display: 'none' }} />

            </Box>
          </Flex>)}

          {showUploadFile && (<Flex flexDirection={'row'} justifyContent={'flex-end'} mt={4}>
            <Button
              isDisabled={!fileUploaded || !selectedFile}
              p={6}
              _hover={{
                background: Colors.primaryButton
              }}
              color={'white'}
              background={Colors.primaryButton}
              width={'40%'}
              mb={10}
              onClick={() => {
                onSelect && onSelect(true)
              }}
            >
              Next
            </Button>
          </Flex>)}


        </Flex>
      </Flex>
    </Flex>
  );
});

