import { Box, Flex, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { SelectOption } from '../selectOption/selectOption';
import { DoctorCalendar } from '../../page';

interface DoctorSelectProps {
  onClick: (selectedDoctor: DoctorCalendar) => void
  doctorsList: DoctorCalendar[]
}

export const DoctorSelect = memo<DoctorSelectProps>(({
  onClick,
  doctorsList,
}) => {

  return (
    <Flex
      h="100%"
      flexDirection={'column'}
      pl={2}
    >

      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        borderRadius={'8px'}
        bg="#FFFFFF"
        h="100%"
        p={4}
        pb={10}>

        <Flex overflow={'hidden'} height={'100%'} justifyContent={'center'} alignItems={'center'}>

          <Flex justifyContent={'center'} flexDirection={'column'} flex={1} overflowY='scroll' height={'100%'}>
            <Box>
              <Text sx={{
                '@media screen and (max-width: 768px)': {
                  fontSize: '24px',
                }
              }} fontSize={28} mb={4}>{"Select the internal medicine doctor you would like to see"}</Text>
            </Box>
            <Box height={'70%'}>
              {doctorsList.map((doc, index) =>
                <SelectOption
                  key={doc.uid}
                  index={index + 1}
                  label={doc.name}
                  onClick={() => {
                    onClick(doc)
                  }} />
              )
              }
            </Box>
          </Flex>
        </Flex>

      </Flex>

    </Flex>
  );
});
