

import { Flex, Spinner, Text } from '@chakra-ui/react';
import { Colors } from '../../constants';
import { memo } from 'react';


interface LoadingSpinnerProps {
  text?: string;
}

export const LoadingSpinner = memo<LoadingSpinnerProps>(({
  text,
}) => {
  return (
    <Flex flexDirection={'column'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
      <Spinner speed='0.80s' borderWidth={5} height={150} width={150} label='Loading' color={Colors.primaryButton} />
      {text && <Text mt={4} fontSize={"lg"}>{text}</Text>}
    </Flex>
  );
})