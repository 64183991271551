//import liraries
import { ModalOverlay, Modal as ModalChakra, ModalContent, ModalBody, Button, Flex, Box, Img, Text } from '@chakra-ui/react';
import React, { memo } from 'react';
import { Colors } from '../../constants';
import { Lotties } from '../../assets';

interface ModalProps {
  children?: React.ReactNode;
  showContacts?: boolean;
  onClose?: () => void;
}

export const ErrorModal = memo<ModalProps>(({
  children,
  showContacts = true,
  onClose = () => { }
}) => {


  return (
    <ModalChakra size={'2xl'} isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{
        '@media screen and (max-width: 768px)': {
          marginLeft: 5,
          marginRight: 5,
        }
      }}>
        <ModalBody >
          <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Box p={2} pt={4}>
              <Img width={120} height={120} src={Lotties.logoCry} />
            </Box>

            {children}
            {showContacts && (<Box mt={4} mb={4} ml={6} mr={6} width={'98%'} bg={Colors.gray} borderRadius={8} p={2}>

              <Text fontSize={14} align={"center"} pb={4} fontWeight={"600"}>
                Maxicare PCC Concierge
              </Text>

              <Text fontSize={14} align={"center"} pb={4} fontWeight={"400"}>
                24/7 Customer Care Email:
              </Text>

              <Text decoration={'underline'} fontSize={14} color={Colors.primaryButton} align={"center"} pb={4} fontWeight={"400"}>
                customercare@maxicare.com.ph
              </Text>

              <Text fontSize={14} align={"center"} pb={4} fontWeight={"400"}>
                24/7 Customer Hotline:
              </Text>
              <Text decoration={'underline'} fontSize={14} color={Colors.primaryButton} align={"center"} pb={4} fontWeight={"400"}>
                +632 8582 1900 (PLDT)
              </Text>
              <Text decoration={'underline'} fontSize={14} color={Colors.primaryButton} align={"center"} fontWeight={"400"}>
                +632 7798 7777 (GLOBE)
              </Text>
            </Box>)}

            <Button _hover={{
              background: Colors.primaryButton
            }} color={'white'} background={Colors.primaryButton} width={'40%'} mb={10} onClick={onClose}>Close</Button>
          </Flex>


        </ModalBody>

      </ModalContent>
    </ModalChakra>
  );
})
