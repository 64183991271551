import request from 'graphql-request'
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

import { useToast } from '@chakra-ui/react'


console.log('time', sessionStorage.getItem('starttime'))
const GET_EVENT_TYPES_SQL = gql(`
  mutation stitchCreateUploadUrl($input: StitchCreateUploadUrlInput!) {
    stitchCreateUploadUrl(input: $input) {
      fields
      uploadId
      url
    }
  }

`)


interface Option<D, E, P> extends
  UseMutationOptions<D, E, P> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type UploadStitchResult = {
  data: {
    stitchCreateUploadUrl: {
      fields: any
      uploadId: string
      url: string
    }

  }
}

interface Param {
  key: string
  mimeType: string
}

export const useCreateStitchUpload = (
  option: Option<UploadStitchResult, Error, Param> = {},
): UseMutationResult<UploadStitchResult, Error, Param> => {

  const toast = useToast()

  const { disableErrorPrompts, ...options } = option

  const handleError =
    (e: Error) => {
      if (disableErrorPrompts) return

      toast({
        title: 'Error',
        description: "An error occured.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })

    }

  const uploadToStitch = async (param: Param): Promise<UploadStitchResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_EVENT_TYPES_SQL,
        { input: param },
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        data: result as UploadStitchResult['data']
      };
    } catch (e) {
      return {
        data: null as unknown as UploadStitchResult['data']
      };
    }
  }

  return useMutation<UploadStitchResult, Error, Param>({
    mutationKey: [QueryKey.UploadStitch],
    mutationFn: (param) => uploadToStitch(param),
    onError: handleError,
    ...options,
  })


}