import { Icon } from "@chakra-ui/react"

export const Calendar = ({ height = 35, width = 35, fill = "none" }) => {
  return (
    <Icon viewBox="0 0 35 35" height={height} width={width}>
      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.96875 11.25H5.03125C4.86719 11.25 4.75 11.1328 4.75 10.9688V10.0312C4.75 9.89062 4.86719 9.75 5.03125 9.75H5.96875C6.10938 9.75 6.25 9.89062 6.25 10.0312V10.9688C6.25 11.1328 6.10938 11.25 5.96875 11.25ZM8.5 10.9688C8.5 11.1328 8.35938 11.25 8.21875 11.25H7.28125C7.11719 11.25 7 11.1328 7 10.9688V10.0312C7 9.89062 7.11719 9.75 7.28125 9.75H8.21875C8.35938 9.75 8.5 9.89062 8.5 10.0312V10.9688ZM10.75 10.9688C10.75 11.1328 10.6094 11.25 10.4688 11.25H9.53125C9.36719 11.25 9.25 11.1328 9.25 10.9688V10.0312C9.25 9.89062 9.36719 9.75 9.53125 9.75H10.4688C10.6094 9.75 10.75 9.89062 10.75 10.0312V10.9688ZM8.5 13.2188C8.5 13.3828 8.35938 13.5 8.21875 13.5H7.28125C7.11719 13.5 7 13.3828 7 13.2188V12.2812C7 12.1406 7.11719 12 7.28125 12H8.21875C8.35938 12 8.5 12.1406 8.5 12.2812V13.2188ZM6.25 13.2188C6.25 13.3828 6.10938 13.5 5.96875 13.5H5.03125C4.86719 13.5 4.75 13.3828 4.75 13.2188V12.2812C4.75 12.1406 4.86719 12 5.03125 12H5.96875C6.10938 12 6.25 12.1406 6.25 12.2812V13.2188ZM10.75 13.2188C10.75 13.3828 10.6094 13.5 10.4688 13.5H9.53125C9.36719 13.5 9.25 13.3828 9.25 13.2188V12.2812C9.25 12.1406 9.36719 12 9.53125 12H10.4688C10.6094 12 10.75 12.1406 10.75 12.2812V13.2188ZM13 7.125V15.375C13 16.0078 12.4844 16.5 11.875 16.5H3.625C2.99219 16.5 2.5 16.0078 2.5 15.375V7.125C2.5 6.51562 2.99219 6 3.625 6H4.75V4.78125C4.75 4.64062 4.86719 4.5 5.03125 4.5H5.96875C6.10938 4.5 6.25 4.64062 6.25 4.78125V6H9.25V4.78125C9.25 4.64062 9.36719 4.5 9.53125 4.5H10.4688C10.6094 4.5 10.75 4.64062 10.75 4.78125V6H11.875C12.4844 6 13 6.51562 13 7.125ZM11.875 15.2344V8.25H3.625V15.2344C3.625 15.3281 3.67188 15.375 3.76562 15.375H11.7344C11.8047 15.375 11.875 15.3281 11.875 15.2344Z" fill="#697481" />
      </svg>
    </Icon>
  )
}