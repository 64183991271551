import request from 'graphql-request'
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

import { useToast } from '@chakra-ui/react'


console.log('time', sessionStorage.getItem('starttime'))
const GET_EVENT_TYPES_SQL = gql(`
  mutation cleoPatientUpdate($updateInput: UpdateCleoPatientInput!) {
    cleoPatientUpdate(input: $updateInput) {
      cleoPatient {
        uid
        isDeviceCreated
      }
    }
  }

`)


interface Option<D, E, P> extends
  UseMutationOptions<D, E, P> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type PatientUpdateResult = {
  response?: unknown | GqlError
}

interface Payload {
  uid: string,
  communicationMethod: string
}

export const useUpdatePatientComMethod = (
  option: Option<PatientUpdateResult, Error, Payload> = {},
): UseMutationResult<PatientUpdateResult, Error, Payload> => {

  const toast = useToast()

  const { disableErrorPrompts, ...options } = option

  const handleError =
    (e: Error) => {
      if (disableErrorPrompts) return

      toast({
        title: 'Error',
        description: "An error occured.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })

    }

  const updatePatientComMethod = async (param: Payload): Promise<PatientUpdateResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_EVENT_TYPES_SQL,
        { updateInput: param },
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useMutation<PatientUpdateResult, Error, Payload>({
    mutationKey: [QueryKey.UpdateCommunicationMethod],
    mutationFn: (param) => updatePatientComMethod(param),
    onError: handleError,
    ...options,
  })


}