import request from 'graphql-request'
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

import { useToast } from '@chakra-ui/react'


const GET_EVENT_TYPES_SQL = gql(`
  mutation cleoPatientEventMetadataEntryCreate($createInput: CreateCleoPatientEventMetadataEntryInput!) {
    cleoPatientEventMetadataEntryCreate(input: $createInput) {
      cleoPatientEventMetadataEntry {
        uid
        parameter {
          uid
          unit
          numberPrecision
          valueType
        }
        extractedForDisplay
        extractedWithUnit
      }
    }
  }
`)


interface Option<D, E, P> extends
  UseMutationOptions<D, E, P> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type MetadataEntryResult = {
  data: {
    cleoPatientEventMetadataEntry: {
      uid: string
    }
  }
}

interface Param {
  patientEvent: {
    uid: string
  }
  /** 
   * Mastermind clinical parameter UID
   * */ 
  parameter: {
    uid: string
  }
  /* @param: Stitch upload ID */
  value: object
}

/***
 * @param: patientEvent: { uid: string }
 */
export const usePatientEventMetadataEntryCreate = (
  option: Option<MetadataEntryResult, Error, Param> = {},
): UseMutationResult<MetadataEntryResult, Error, Param> => {

  const toast = useToast()

  const { disableErrorPrompts, ...options } = option

  const handleError =
    (e: Error) => {
      if (disableErrorPrompts) return

      toast({
        title: 'Error',
        description: "An error occured.",
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })

    }

  const uploadDoctorsNote = async (param: Param): Promise<MetadataEntryResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_EVENT_TYPES_SQL,
        { createInput: param },
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)
      return {
        data: result as MetadataEntryResult['data']
      };
    } catch (e) {
      return {
        data: null as unknown as MetadataEntryResult['data']
      };
    }
  }

  return useMutation<MetadataEntryResult, Error, Param>({
    mutationKey: [QueryKey.MetadataEntryCreate],
    mutationFn: (param) => uploadDoctorsNote(param),
    onError: handleError,
    ...options,
  })


}