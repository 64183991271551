import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

const GET_EVENT_GQL = gql(`
  query cleoPatientEvents {
    cleoPatientEvents(sortBy: CREATED_ON, sortDesc: true) {
      totalCount
      edges {
        node {
          uid
          status
          eventUrl
          patient {
            name
            createdOn
          }
          calendar {
            uid
            name
          }
          event {
            name
            providerEventType
          }
          startOn
          createdOn
          isPatientShowed
          isConfirmed
        }
      }
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type PatientEventsResult = {
  response?: unknown | GqlError
}


export const useGetPatientEvents = (
  option: Option<PatientEventsResult, Error> = {}): UseQueryResult<PatientEventsResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getPatientEvents = async (): Promise<PatientEventsResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_EVENT_GQL,
        {},
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useQuery<PatientEventsResult, Error>({
    queryKey: [QueryKey.PatientEvents],
    queryFn: () => getPatientEvents(),
    ...options,
  })


}