//import liraries
import React, { memo } from 'react';

import Lottie from "lottie-react";
import { Box, Flex, Text } from '@chakra-ui/react';
import { Lotties } from '../../assets';

export interface LoadingProps {
  text?: string;
}

export const Loading = memo<LoadingProps>(({
  text,
}) => {
  return (
    <Flex direction={'column'} alignItems={'center'} justifyItems={'center'} flex={1}>
      <Box width={400} height={400} >
        <Lottie animationData={Lotties.loading} loop={true} />
      </Box>
      {text && (<Box>
        <Text fontSize='2xl' align={'center'}>{text}</Text>
      </Box>)}
    </Flex>
  );
});
