import request from 'graphql-request'
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import { gql } from "@apollo/client";
import { QueryKey, Variables } from '../../../constants';

const GET_EVENT_TYPES_SQL = gql(`
  query cleoClinicCalendars ($uidIn: [String]) {
    cleoClinicCalendars (eventTypes_Uid_In: $uidIn, deactivatedOn_Isnull: true) {
      edges {
        node {
          uid
          name
          metadata
          eventTypes {
            eventType{
              uid
              name
            }
          }
        }
      }
    }
  }
`)


interface Option<D, E> extends
  UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
}

type GqlError = {
  message: string
  errorUid: string
  requestLogUid: string
}

export type EventCalendarsResult = {
  response?: unknown | GqlError
}


export const useGetEventCalendars = (
  eventUid: string[],
  option: Option<EventCalendarsResult, Error> = {}): UseQueryResult<EventCalendarsResult, Error> => {

  const { disableErrorPrompts, ...options } = option

  const getEventTypes = async (): Promise<EventCalendarsResult> => {
    try {
      const result = await request(
        Variables.URL,
        GET_EVENT_TYPES_SQL,
        { uidIn: eventUid },
        { "X-SESSION": sessionStorage.getItem('session') as string }
      ).then((data) => data)
        .catch((error) => error as GqlError)

      return {
        response: result
      };
    } catch (e) {
      return {
        response: null
      };
    }
  }

  return useQuery<EventCalendarsResult, Error>({
    queryKey: [QueryKey.EventCalendar],
    queryFn: () => getEventTypes(),
    ...options,
  })


}
