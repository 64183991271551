//import liraries
import { Flex, Text } from '@chakra-ui/react';
import React, { memo } from 'react';

interface SelectOptionProps {
  label: string
  index?: number
  onClick: () => void
  style?: React.CSSProperties
}

export const SelectOption = memo<SelectOptionProps>(({
  label,
  index,
  onClick,
  style,
}) => {
  return (
    <Flex
      style={style}
      key={label}
      onClick={onClick}
      cursor={'pointer'}
      alignItems={'center'} p={1}
      borderRadius={8}
      border={'1px solid rgb(4, 69, 175)'}
      mb="8px"
      flexDirection={'row'}
      bg={'rgba(4, 69, 175, 0.1)'}
      _hover={{
        bg: 'rgba(4, 69, 175, 0.2)'
      }}
    >
      {index && (<Flex
        p={0.5} pl={2} pr={2}
        marginRight={2} borderRadius={2}
        bg="white"
        border={'1px solid rgb(4, 69, 175)'}
      >
        <Text sx={{
          '@media screen and (max-width: 768px)': {
            fontSize: '16px',
          }
        }} color={'rgb(4, 69, 175)'} fontSize={20} fontWeight={600}>{index}</Text>
      </Flex>)}
      <Flex>
        <Text sx={{
          '@media screen and (max-width: 768px)': {
            fontSize: '16px',
          }
        }} color={'rgb(4, 69, 175)'} fontSize={20} fontWeight={400}>{label}</Text>
      </Flex>
    </Flex>
  );
})

